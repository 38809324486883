import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Grid, H, P, Button, Wrapper } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'
import { getWidthOfContainerColumns } from '@farewill/ui/helpers/layout'
import { screenMin } from '@farewill/ui/helpers/responsive'

import PreventOrphan from 'components/PreventOrphan'
import AccoladesImage from 'components/AccoladesImage'
import HeroWrapperWithTextures from '../../components/HeroWrapperWithTextures'
import { CTA } from './constants'

const StyledButton = styled(Button.White)`
  ${screenMin.m`
    max-width: 320px;
  `}
`

const StyledP = styled(P)`
  ${screenMin.m`
    font-size: ${FONT.SIZE.L}
    `}
`

const Hero = (): React.ReactElement => (
  <HeroWrapperWithTextures edgeBackgroundColor={COLOR.WHITE} centered>
    <Grid padding={[0, 0, 'M', 0]}>
      <Grid.Item>
        <H
          tag="h1"
          size="XL"
          decorative
          maxWidth={getWidthOfContainerColumns(7, 'L')}
          margin={[0, 'auto']}
        >
          Check if you need probate, in minutes
        </H>
        <StyledP
          maxWidth={getWidthOfContainerColumns(7, 'L')}
          color={COLOR.BLACK}
          margin={['S', 'auto', 'L']}
        >
          <PreventOrphan>
            Finding out if you need probate can be confusing, so we created a
            free tool that checks for you. Answer a few questions to get
            started.
          </PreventOrphan>
        </StyledP>
        <StyledButton stretch tag={Link} to={CTA.path}>
          <P strong margin={[0]}>
            {CTA.text}
          </P>
        </StyledButton>
        <Wrapper margin={['M', 0, 0]} marginFromL={['XL', 0, 0]} centered>
          <AccoladesImage />
        </Wrapper>
      </Grid.Item>
    </Grid>
  </HeroWrapperWithTextures>
)

export default Hero
