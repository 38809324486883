import React from 'react'
import styled from 'styled-components'

import { screenMin } from '@farewill/ui/helpers/responsive'
import { Divider, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens/color'

import DefaultLayout from 'layouts/DefaultLayout'
import { PRODUCTS } from 'lib/products/constants'
import PROBATE_JSONLD from 'lib/json-ld/probate'
import JSONLD from 'components/JSONLD'
import FourCards from 'components/FourCards'
import TrustpilotCarousel from 'components/TrustpilotWidget/TrustpilotCarousel'
import { TAGS } from 'lib/trustpilot/constants'
import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions'
import { useProbateFAQ } from 'views/probate/shared/queries/probateFAQ'
import PreferToTalk from '../../components/PreferToTalk'
import Options from '../../components/Options'
import Explainer from '../../components/Explainer'
import { CARDS_DATA, PRODUCT_OPTIONS_DESCRIPTION } from '../../shared/constants'
import Hero from './Hero'
import { CTA } from './constants'

const StyledMobileDivider = styled(Divider)`
  ${screenMin.m`
    display: none;
  `}
`

const Required = (): React.ReactElement => {
  const faqSection = useProbateFAQ()

  return (
    <Wrapper background={COLOR.WHITE} style={{ overflowX: 'hidden' }}>
      <JSONLD data={PROBATE_JSONLD} />
      <DefaultLayout
        title="Probate Required & Costs UK | Probate Required Calculator"
        description="Find out how much probate will cost using our probate Required calculator, with tips on how to save money and get probate sorted quickly and simply."
        product={PRODUCTS.PROBATE}
        headerType="compact"
        footerType="compact"
        headerColor={COLOR.ACCENT.PRIMARY}
        meta={[
          {
            name: 'robots',
            content: 'noindex,follow',
          },
        ]}
        alignContent="start"
        footerShowFwlsDisclaimer
      >
        <Hero />
        <Explainer cta={CTA} />
        <Options description={PRODUCT_OPTIONS_DESCRIPTION} />
        <FourCards data={CARDS_DATA} />
        <Divider container />
        <PreferToTalk />
        <StyledMobileDivider container />
        <TrustpilotCarousel tag={TAGS.PROBATE} />
        <Divider container />
        <Wrapper container>
          <FrequentlyAskedQuestions
            title={faqSection.title}
            questions={faqSection.questions}
            selectedProduct={PRODUCTS.PROBATE}
            image="e_trim/illustrations/blob-bird-binoculars"
            showCallUsTodayCta
            ctaSubtitle="Our specialists can help you work out of you need probate, how much it costs, and the different options available."
          />
        </Wrapper>
        <Divider />
      </DefaultLayout>
    </Wrapper>
  )
}

export default Required
