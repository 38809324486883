import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, Image } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'

import WrapperWithEdge from 'components/WrapperWithEdge'

const StyledTextureLeft = styled(Image)`
  position: absolute;
  pointer-events: none;
  z-index: -1;
  max-height: 90%;
  left: 0;
  top: 0;
`

const StyledTextureTop = styled(Image)`
  position: absolute;
  pointer-events: none;
  z-index: -1;
  transform: rotate(-20deg);
  transform-origin: bottom right;
  max-height: 90%;
  right: -20%;

  ${screenMax.m`
    top: -8%;
    right: -20%;
  `}

  ${screenMin.l`
    top: -13%;
    right: 15%;
    margin-right: -400px;
  `}
`

const StyledWrapperWithEdge = styled(WrapperWithEdge)`
  position: relative;
  overflow: hidden;
`

/* eslint-disable react/jsx-props-no-spreading */

const HeroWrapperWithTextures = ({
  children,
  edgeBackgroundColor,
  ...props
}) => (
  <StyledWrapperWithEdge
    direction="down"
    edgeBackgroundColor={edgeBackgroundColor}
  >
    <Wrapper container centered {...props}>
      <StyledTextureLeft
        path="textures/detail-1"
        width={160}
        widthFromM={200}
        widthFromL={275}
        loading="auto"
      />
      <StyledTextureTop
        path="textures/detail_new_yellow02"
        width={200}
        widthFromM={280}
        widthFromL={350}
        loading="auto"
      />

      {children}
    </Wrapper>
  </StyledWrapperWithEdge>
)

HeroWrapperWithTextures.propTypes = {
  children: PropTypes.node.isRequired,
  edgeBackgroundColor: PropTypes.string,
}

HeroWrapperWithTextures.defaultProps = {
  edgeBackgroundColor: COLOR.WHITE,
}

export default HeroWrapperWithTextures
